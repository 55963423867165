<template>
  <div id="app">
    <div class="components-container">
      <CryptoInfo />
      <BinanceAnnouncements />
      <SolanaTokenInfo v-if="false" />
      <EtfFlowMonitor />
    </div>
  </div>
</template>

<script>
import CryptoInfo from "./components/CryptoInfo.vue";
import BinanceAnnouncements from "./components/BinanceAnnouncements.vue";
import SolanaTokenInfo from "./components/SolanaTokenInfo.vue";
import EtfFlowMonitor from "./components/EtfFlowMonitor.vue"; // 导入 ETF 流入和流出监控组件

export default {
  name: "App",
  components: {
    CryptoInfo,
    BinanceAnnouncements,
    SolanaTokenInfo,
    EtfFlowMonitor, // 注册 ETF 流入和流出监控组件
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}

h1 {
  margin-bottom: 20px;
}

.components-container {
  display: flex;
  justify-content: space-between; /* 使组件尽可能宽 */
  flex-wrap: wrap; /* 允许换行 */
  max-width: 1200px; /* 设置最大宽度 */
  margin: 0 auto; /* 居中 */
}
</style>