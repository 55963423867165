<template>
  <div class="token-info">
    <h2>Solana Token Info</h2>
    <div class="info-container">
      <div class="info-item">
        <h3>Price</h3>
        <p>{{ price ? `$${price}` : "Loading..." }}</p>
      </div>
      <div class="info-item">
        <h3>Market Cap</h3>
        <p>{{ marketCap ? `$${marketCap}` : "Loading..." }}</p>
      </div>
      <div class="info-item">
        <h3>Holders</h3>
        <p>{{ holders || "Loading..." }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted } from "vue";
import { Connection, PublicKey } from "@solana/web3.js";
import { TOKEN_PROGRAM_ID, getMint } from "@solana/spl-token";

export default {
  name: "SolanaTokenInfo",
  setup() {
    const price = ref(null);
    const marketCap = ref(null);
    const holders = ref(null);
    let intervalId = null;

    const TOKEN_ADDRESS = "GxdTh6udNstGmLLk9ztBb6bkrms7oLbrJp5yzUaVpump";
    const connection = new Connection(
      "https://rpc.ankr.com/solana",
      "confirmed"
    );

    const fetchTokenInfo = async () => {
      try {
        const tokenPubkey = new PublicKey(TOKEN_ADDRESS);

        // 获取所有账户
        const accounts = await connection.getProgramAccounts(TOKEN_PROGRAM_ID, {
          filters: [
            {
              dataSize: 165, // Token account size
            },
            {
              memcmp: {
                offset: 0,
                bytes: tokenPubkey.toBase58(),
              },
            },
          ],
        });

        // 计算持币地址数量
        holders.value = accounts.length;

        // 获取代币铸造信息
        const mintInfo = await getMint(connection, tokenPubkey);
        console.log("Mint Info:", {
          supply: mintInfo.supply.toString(),
          decimals: mintInfo.decimals,
        });

        // 计算实际供应量（考虑小数位）
        const actualSupply =
          Number(mintInfo.supply) / Math.pow(10, mintInfo.decimals);
        console.log("Actual Supply:", actualSupply);

        // 暂时将市值设置为总供应量（后续会乘以价格）
        marketCap.value = actualSupply.toLocaleString();
      } catch (error) {
        console.error("Error fetching token info:", error);
      }
    };

    onMounted(() => {
      fetchTokenInfo();
      intervalId = setInterval(fetchTokenInfo, 60000); // 每分钟更新一次
    });

    onUnmounted(() => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    });

    return {
      price,
      marketCap,
      holders,
    };
  },
};
</script>

<style scoped>
.token-info {
  width: 100%;
  margin: 20px auto;
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.info-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

.info-item {
  text-align: center;
  padding: 10px;
}

.info-item h3 {
  margin-bottom: 10px;
  color: #666;
}

.info-item p {
  font-size: 1.2em;
  font-weight: bold;
  color: #2c3e50;
}
</style>
